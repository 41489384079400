import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { EMPTY, catchError, map, tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoadingStateEnum } from 'src/app/shared/enums/loading-state.enum';

import { LoadStudentByNameAction } from './student-schools.action';
import { toGrades } from '../../mappers/to-grades.mapper';
import { GradeLivModel } from '../../models/grade.model';
import { SchoolModel } from '../../models/school.model';
import { CyclesService } from '../../services/cycles.service';

export interface StudentSchoolsStateModel {
  studentIds: number[];
  studentSchools: SchoolModel[];
  grades: GradeLivModel[];
  requestStatus: LoadingStateEnum;
}

const STUDENT_SCHOOLS_STATE_TOKEN = new StateToken<StudentSchoolsStateModel>(
  'studentSchools'
);
const initialState: Partial<StudentSchoolsStateModel> = {
  studentIds: [],
  studentSchools: [],
  grades: []
};

@State<StudentSchoolsStateModel>({
  name: STUDENT_SCHOOLS_STATE_TOKEN,
  defaults: {
    ...(initialState as StudentSchoolsStateModel),
    requestStatus: LoadingStateEnum.idle
  }
})
@Injectable()
export class StudentSchoolsState {
  constructor(
    private cyclesService: CyclesService,
    private toast: ToastService
  ) {}

  @Selector() static studentschool(studentSchool: StudentSchoolsStateModel) {
    return studentSchool;
  }
  @Selector() static schools({ studentSchools }: StudentSchoolsStateModel) {
    return studentSchools;
  }
  @Selector() static grades({ grades }: StudentSchoolsStateModel) {
    return grades;
  }

  @Selector() static studentIds({ studentIds }: StudentSchoolsStateModel) {
    return studentIds;
  }

  @Selector() static isLoading({ requestStatus }: StudentSchoolsStateModel) {
    return requestStatus === LoadingStateEnum.loading;
  }

  @Action(LoadStudentByNameAction) loadStudentByName(
    { patchState }: StateContext<StudentSchoolsStateModel>,
    { payload }: LoadStudentByNameAction
  ) {
    patchState({ requestStatus: LoadingStateEnum.loading });
    return this.cyclesService.getStudentDataByName(payload.trim()).pipe(
      catchError(() => {
        this.toast.error(
          'Não foi possível encontrar o aluno. Por favor, tente novamente.'
        );
        patchState({ ...initialState, requestStatus: LoadingStateEnum.error });
        return EMPTY;
      }),
      map((data) => {
        return { ...data, grades: toGrades(data.grades) };
      }),
      tap((data) => {
        patchState({ ...data, requestStatus: LoadingStateEnum.loaded });
      })
    );
  }
}
