import { GradeLivModel } from '../models/grade.model';

export function toGrades(grades: GradeLivModel[]) {
  if (!grades?.length) {
    return [];
  }
  return grades
    .map((grade) => ({
      ...grade,
      name: grade.name
        .replace('Regular', '')
        .trim()
        .replace(/^(Infantil \d+)/, (match) => `${match} anos`)
    }))
    .sort((a, b) => {
      if (a.segment.name < b.segment.name) {
        return -1;
      }
      if (a.segment.name > b.segment.name) {
        return 1;
      }
      return a.name.localeCompare(b.name, 'pt', { numeric: true });
    });
}
